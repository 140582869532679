import { $themeBreakpoints } from '@themeConfig'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    paymentCompleted: false,
    userData: {},
    globalParams: {
      countries: [],
      currencies: [],
      unitTypes: [],
      account_email: null,

    },
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    UPDATE_USER_DATA(state, val) {
      state.userData = val
    },
  },
  actions: {
    refreshGlobalParams() {
      axios
        .get('/api/global-params')
        .then(response => {
          const globalParamData = response.data.data

          this.state.app.globalParams.countries = []

          this.state.app.globalParams.currencies = []
          this.state.app.globalParams.unitTypes = []
          this.state.app.globalParams.account_email = globalParamData.account_email

          globalParamData.countries.forEach(val => this.state.app.globalParams.countries.push({ label: `${val.name}`, value: val.id }))
          globalParamData.currencies.forEach(val => this.state.app.globalParams.currencies.push({ label: `${val.currency_code} - ${val.currency_name}`, value: val.id }))

          globalParamData.units.forEach(val => this.state.app.globalParams.unitTypes.push({ label: `${val.name}`, value: val.id }))
          // this.state.app.globalParams.unitTypes.push({ label: 'None', value: 0 })
        })
        // eslint-disable-next-line no-unused-vars, no-alert
        .catch(error => alert('Global params fetch error'))
    },
    changeLanguage(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/my-settings/change-language', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCountries(ctx, d) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/countries', { params: d })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCurrencies(ctx, d) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/currencies', { params: d })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMy(ctx, d) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/account/getmy', { params: d })
          .then(response => {
            const userData = response.data.data
            localStorage.setItem('userData', JSON.stringify(userData))
            //    this.$ability.update(userData.ability)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    suggestProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/products-suggest', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    suggestClients(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/clients-suggest', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAccountData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/account/settings', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // notifications
    fetchNotifications(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/notifications', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    notificationMarkAsReaded(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/notifications-mark-as-readed', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAppUsageSteps(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/account/update-app-usage-steps', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
