/* eslint-disable */
export default {

  calculateTotals(invoiceData) {
    const data = JSON.parse(JSON.stringify(invoiceData));
    const totals = {
        subtotal: 0,
        total: 0,
        paid: 0,
        discount: 0,
        balance: 0,
        taxes: [],
    };

    let rawTaxTotal = 0;

    // Ürünlerin vergilerini ve indirimlerini hesapla
    data.content.items.forEach(val => {
        val.amount = val.unit_price * val.quantity; // Ürün tutarı

        // Ürün İndirimi
        if (val.discount?.enable) {
            let discountPrice = 0;
            if (val.discount.type === 1) {
                discountPrice = (val.discount.discount / 100) * val.amount; // Yüzde indirim
            } else if (val.discount.type === 2) {
                discountPrice = val.discount.discount; // Sabit tutar indirimi
            }
            val.amount -= discountPrice; // İndirimi uygula
        }

        // Ürün Vergileri
        let itemTaxAmountTotal = 0;
        val.applied_taxes.forEach(appliedTax => {
            const tax = data.content.taxes.find(t => t.id === appliedTax.tax_id);
            if (tax) {
                appliedTax.tax_name = tax.tax_name;
                appliedTax.amount = (val.amount * (appliedTax.tax_rate / 100)); // Vergi tutarı
                const existingTax = totals.taxes.find(t => t.tax_id === appliedTax.tax_id && t.tax_rate === appliedTax.tax_rate);
                if (existingTax) {
                    existingTax.amount += appliedTax.amount;
                } else {
                    totals.taxes.push({ ...appliedTax });
                }
                itemTaxAmountTotal += appliedTax.amount;
            }
        });

        rawTaxTotal += itemTaxAmountTotal; // Ürünün toplam vergi tutarını ekle
        totals.subtotal += val.amount; // Subtotal'a ekle
    });

    // Subtotal İndirimi
    if (data.content.discount) {
        if (data.content.discount.type === 1) {
            totals.discount = (data.content.discount.discount / 100) * totals.subtotal; // Yüzde indirim
        } else if (data.content.discount.type === 2) {
            totals.discount = data.content.discount.discount; // Sabit tutar indirimi
        }
    }

    const discountedSubtotal = totals.subtotal - totals.discount;

    // Vergileri İndirim Oranına Göre Güncelle
    let discountedTaxTotal = 0;
    if (totals.discount > 0) {
        const discountRate = totals.discount / totals.subtotal; // İndirim oranı
        totals.taxes.forEach(tax => {
            tax.amount *= (1 - discountRate); // Vergiyi güncelle
            discountedTaxTotal += tax.amount; // Güncellenmiş vergi toplamı
        });
    } else {
        discountedTaxTotal = rawTaxTotal;
    }

    // Total ve Balance Hesaplaması
    totals.paid = data.content.paid_amount || 0;
    totals.total = discountedSubtotal + discountedTaxTotal; // Toplam tutar
    totals.balance = totals.total - totals.paid; // Kalan bakiye

    data.content.totals = totals;
    return data;
}
}
