<template>
  <section
    v-if="Object.keys(pricing).length"
    id="pricing-plan"
  >
    <b-sidebar
      v-if="pageIsLoading==false"
      id="checkoutSidebar"
      ref="checkoutSidebar"
      width="100%"
      shadow
      bg-variant="white"
      left
    >
      <checkout
        v-if="selectedPlan"
        :selected-plan="selectedPlan"
        :payment-period="status"
      />
    </b-sidebar>
    <b-overlay
      id="overlay-background"
      :show="pageIsLoading"
      variant="light"

      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <b-card

          class="text-center"
        >

          <h2>
            <b-spinner

              class="mr-1"
              label="Loading"
            />
            Loading...
          </h2>

        </b-card>
      </template>
      <div v-if="!pageIsLoading">
        <!-- title text and switch button -->
        <div class="d-flex justify-content-end align-items-center">
          <b-link
            class="p-2"

            @click="goToSettingsPage()"
          > <feather-icon
            icon="XIcon"
            size="35"
            class="mr-1"
          />

          </b-link>
        </div>
        <div class="text-center">
          <h1 class="mt-5">
            Pricing Plans
          </h1>
          <p class="mb-2 pb-75">
            All plans include 40+ advanced tools and features to boost your product. Choose the best plan to fit your needs.
          </p>
          <div class="d-flex align-items-center justify-content-center mb-5 pb-50">
            <h6 class="mr-1 mb-0">
              Monthly
            </h6>
            <b-form-checkbox
              id="priceSwitch"
              v-model="status"
              name="price-switch"
              value="yearly"
              unchecked-value="monthly"
              switch
              @input="tooglePlan"
            />
            <h6 class="ml-50 mb-0">
              Annually
            </h6>
          </div>
        </div>
        <!--/ title text and switch button -->

        <!-- pricing plan cards -->
        <b-row class="pricing-card">
          <b-col
            offset-sm-2
            sm="10"
            md="12"
            offset-lg="2"
            lg="10"
            class="mx-auto"
          >
            <b-row>

              <b-col
                v-for="(plan,index) in pricing.plans"
                :key="index"
                md="4"
              >
                <b-card
                  class="popular"
                  align="center"
                >
                  <div
                    v-show="plan.popular"
                    class="pricing-badge text-right"
                  >
                    <b-badge
                      v-if="plan.is_popular"
                      variant="light-primary"
                      pill
                    >
                      Popular
                    </b-badge>
                  </div>
                  <!-- img -->
                  <b-img
                    v-if="plan.img"
                    :src="plan.img"
                    class="mb-1"
                    alt="svg img"
                  />
                  <!--/ img -->
                  <h3>{{ plan.title }}</h3>
                  <b-card-text>{{ plan.subtitle }}</b-card-text>

                  <!-- annual plan -->
                  <div
                    v-if="!plan.is_enterprise"
                    class="annual-plan"
                  >
                    <div class="plan-price mt-2">
                      <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                      <span class="pricing-basic-value font-weight-bolder text-primary">{{ monthlyPlanShow ? plan.monthlyPrice:plan.yearlyPlan.perMonth }}</span>
                      <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                    </div>
                    <small
                      v-show="!monthlyPlanShow && !plan.without_payment"
                      class="annual-pricing text-muted"
                    >USD {{ plan.yearlyPlan.totalAnual }} / year</small>
                  </div>
                  <!--/ annual plan -->
                  <!-- annual plan -->
                  <div
                    v-if="plan.is_enterprise"
                    class="annual-plan"
                  >
                    <div class="plan-price mt-2">

                      <sub class="pricing-duration text-body font-medium-1 font-weight-bold">Contact Us</sub>
                    </div>
                  </div>
                  <!--/ annual plan -->
                  <!-- plan benefit -->
                  <b-list-group class="list-group-circle text-left">
                    <b-list-group-item
                      v-for="(data,index2) in plan.planBenefits"
                      :key="index2"
                    >
                      {{ data }}
                    </b-list-group-item>
                  </b-list-group>
                  <!--/ plan benefit -->

                  <!-- buttons -->

                  <b-button
                    v-if="!plan.without_payment && plan.id==subscriptionData.plan_id && subscriptionData.payment_period=='monthly' &&status=='monthly'"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    block
                    class="mt-2"
                    :disabled="true"
                    @click="selectPlan(plan)"
                  >
                    Your current plan
                  </b-button>
                  <b-button
                    v-else-if="!plan.without_payment && plan.id==subscriptionData.plan_id && subscriptionData.payment_period=='monthly' &&status=='yearly'"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    class="mt-2"
                    variant="success"
                    @click="selectPlan(plan)"
                  >
                    Upgrade to Yearly Payment
                  </b-button>
                  <b-button
                    v-else-if="plan.without_payment"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    class="mt-2"
                    variant="secondary"
                    disabled
                    @click="selectPlan(plan)"
                  >
                    Free plan (Always active)
                  </b-button>
                  <b-button
                    v-else-if="!plan.without_payment && !plan.is_enterprise"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    block
                    class="mt-2"
                    @click="selectPlan(plan)"
                  >
                    Upgrade
                  </b-button>
                  <b-button
                    v-else-if="plan.is_enterprise"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    block
                    class="mt-2"
                    @click="goToContactpage"
                  >
                    Contact Us
                  </b-button>
                </b-card>
              </b-col>

            </b-row>
          </b-col>
        </b-row>
      </div>
      <stripe-checkout ref="refStripeCheckout" />
      <!--/ pricing plan cards -->

      <!-- pricing free trial -->
      <div class="pricing-free-trial">
        <b-row>
          <b-col
            lg="10"
            offset-lg="3"
            class="mx-auto"
          >
            <div class="pricing-trial-content d-flex justify-content-between">
              <div class="text-center text-md-left mt-3">
                <h3 class="text-primary">
                  Still not convinced? Start with FREE BASIC plan!
                </h3>
                <h5>You will get full access forever. No credit card required</h5>
              </div>

              <!-- images -->
              <b-img
                fluid
                :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
                class="pricing-trial-img"
                alt="svg img"
              />
            <!--/ images -->
            </div>
          </b-col>
        </b-row>
      </div>
      <!--/ pricing free trial -->

      <!-- pricing faq -->
      <div
        v-if="true==false"
        class="pricing-faq"
      >
        <h3 class="text-center">
          FAQ's
        </h3>
        <p class="text-center">
          Let us help answer the most common questions.
        </p>
        <b-row class="py-2">
          <b-col
            lg="10"
            offset-lg="2"
            class="mx-auto"
          >
            <app-collapse
              accordion
              type="margin"
            >

              <app-collapse-item
                v-for="(data,index) in pricing.qandA"
                :key="index"
                :title="data.question"
              >
                {{ data.ans }}
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>

      </div>
    <!--/ pricing faq -->
    </b-overlay>
  </section>
</template>

<script>
import {
  BFormCheckbox, BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BBadge, BOverlay, BSpinner, BSidebar, BLink,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import accountStoreModule from '../accountStoreModule'
import checkout from './Checkout.vue'
import stripeCheckout from './StripeCheckout.vue'
/* eslint-disable global-require */
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BLink,
    BOverlay,
    BRow,
    BSpinner,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
    BSidebar,
    checkout,
    stripeCheckout,
  },
  directives: {
    Ripple,
  },
  data() {
    this.publishableKey = 'pk_live_lIgFqlzsYc2znf2XTkwLmTrE'
    return {
      status: 'monthly',
      monthlyPlanShow: true,
      pricing: {},
      subscriptionData: null,
      pageIsLoading: true,
      selectedPlan: null,
      checkoutMode: 'stripe',
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    pricing(val) {
      this.getSubscription()
    },
  },
  created() {
    this.$http.get('/pricing/data').then(res => { this.pricing = res.data })
  },
  methods: {
 goToSettingsPage() {
     // this.$destroy()
     this.$router.replace({ path: '/settings#manage-subscription' })
    },
    goToContactpage() {
      window.open('https://fastinvoice.me/en/contact-us', '_blank')
    },
    getSubscription() {
      store
        .dispatch('app-subscription/fetchSubscription')
        .then(response => {
          this.subscriptionData = response.data.data
          if (!this.subscriptionData.without_payment) {
            this.$router.push({ path: '/settings#manage-subscription' })
          }
          this.pricing.plans.forEach(val => {
            if (val.id === this.subscriptionData.id) {
              this.selectedPlan = val
            }
          })
          this.pageIsLoading = false
        })
        .catch(() => {
          this.pageIsLoading = false
        })
    },
    submitStripe() {
      if (this.stripeOptions.paymentCustomer != null) {
        // You will be redirected to Stripe's secure checkout page
        this.$refs.checkoutRef.redirectToCheckout()
      }
    },
    tooglePlan() {
      if (this.status === 'monthly') {
        this.monthlyPlanShow = true
      } else {
        this.monthlyPlanShow = false
      }
    },
    // checkout via stripe
    startStripeCheckout(plan) {
      this.pageIsLoading = true
      this.selectedPlan = plan
      store
        .dispatch('app-subscription/stripeSession', { packet_id: this.selectedPlan.id, payment_period: this.status })
        .then(response => {
          this.pageIsLoading = false
          window.location.href = response.data.data
        })
        .catch(() => {
          this.pageIsLoading = false
          // eslint-disable-next-line no-alert
          alert('Fail')
        })
    },
    // checkout via app
    selectPlan(plan) {
      if (this.checkoutMode === 'stripe') {
        this.startStripeCheckout(plan)
      } else {
        this.selectedPlan = plan

        this.$refs.refStripeCheckout.startSession(this.selectedPlan, this.status)
        this.$root.$emit('bv::toggle::collapse', 'checkoutSidebar')
      }
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-subscription'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, accountStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    return { }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
