<template>
  <div>
    <stripe-checkout
      v-if="elementReady"
      ref="checkoutRef"
      :pk="publishableKey"
      :session-id="sessionId"
    />
  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import store from '@/store'

export default {
  components: {
    StripeCheckout,
  },
  data() {
    this.publishableKey = 'pk_test_51KLpKkIcXzXSrPC5Eo53OpqXPTBDRhmxxeJgIiKm8DgG9dFGior7bZxlSWthhLJ79Is2lboY5boCXNrybhxEo96t00m3HP0iih'
    return {
      loading: false,
      sessionId: '',
      elementReady: false,
      successURL: process.env.VUE_APP_API_URL,
      cancelURL: process.env.VUE_APP_API_URL,
    }
  },
  created() {

  },
  methods: {
    startSession(selectedPlan, paymentPeriod) {
      store
        .dispatch('app-account/stripeSession', { packet_id: selectedPlan.id, payment_period: paymentPeriod })
        .then(response => {
          this.sessionId = response.data.data
          this.elementReady = true

          // You will be redirected to Stripe's secure checkout page
          this.$refs.checkoutRef.redirectToCheckout()
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          alert('Fail')
        })
    },
  },
}
</script>
