<template>
  <b-modal
    id="discountSelectorModal"
    ref="discountSelectorModal"
    title="Edit discount"
    ok-only
    ok-title="Save discount"

    @ok="saveData"
  >
    <div v-if="discountData">
      <!-- form -->

      <status-alert
        :is-success="isSuccess"
        :error-data="errorData"
        :success-msg="successMsg"
      />
      <b-row>
        <b-col
          md="12"
          lg="12"
        >
          <validation-observer

            ref="formClient"
          > <b-form
            class="p-2"
          >
            <b-alert

              show

              fade
              class="mb-3"
              variant="warning"
            >
              <div class="alert-body ">
                The discount will be applied to the subtotal. This does not include discounts added to specific items.
              </div>
            </b-alert>
            <validation-provider #default="{ errors }">
              <b-form-group
                label-for="price"
                label="Discount"
              >
                <b-input-group>
                  <input
                    v-model="discountData.discount"
                    class="form-control-lg form-control"
                    type="number"
                    :placeholder="discountData.type === 1 ? 'Enter discount (0-100)' : 'Enter discount'"
                    :min="0"
                    :max="discountData.type === 1 ? 100 : discountData.discount"
                    @input="DiscountLimit"
                  >
                  <b-button-group size="lg">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"

                      :variant="discountData.type==1?'dark':'outline-dark'"
                      @click="discountData.type=1"
                    >
                      %
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      :variant="discountData.type==2?'dark':'outline-dark'"
                      @click="discountData.type=2"
                    >
                      {{ currency }}
                    </b-button>

                  </b-button-group>
                </b-input-group>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!--/ Name -->

          </b-form>
          </validation-observer>
        </b-col>

      </b-row>

    </div>
  </b-modal>
</template>

<script>
import {
  BForm, BFormGroup, BRow, BCol, BFormInvalidFeedback, BModal, BButton, BInputGroup, BButtonGroup, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'

export default {
  components: {
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BButtonGroup,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BAlert,
    BModal,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    currency: {
      type: String,
      default: '',
    },
    subtotal: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {

      discountClaveOptions: null,
      required,
      alphaNum,
      email,
      isSuccess: false,
      successMsg: 'Discount updated',
      errorData: null,
      blankDiscountData: {
        discount: 0,
        type: 1,
      },
      discountData: null,
    }
  },
  watch: {
    discountData: {
      deep: true,
      handler(val) {
        if (val.type === 1) {
          this.discountClaveOptions.prefix = '% '
        } else {
          this.discountClaveOptions.prefix = `${this.currency} `
        }
      },
    },
  },
  created() {
    this.resetForm()
    this.discountClaveOptions = this.$staticParams.currencyClaveOptions()
  },
  methods: {
    DiscountLimit() {
  const discountValue = parseFloat(this.discountData.discount)
  if (this.discountData.type === 1) {
    // 1 den 100 e kadar indirim (100u ne asabilir nede 0 assagisina ilabilir)
    if (discountValue < 0) {
      this.discountData.discount = 0
    } else if (discountValue > 100) {
      this.discountData.discount = 100
    }
  } else if (this.discountData.type === 2) {
    // indirimi toplam parayla sabitleme
    if (discountValue < 0) {
      this.discountData.discount = 0
    } else if (discountValue > this.subtotal) {
      this.discountData.discount = this.subtotal
    }
  }
},

    showEditor(discountData, subTotal) {
      this.subTotal = subTotal
      if (discountData == null) {
        this.resetForm()
      } else {
        this.discountData = JSON.parse(JSON.stringify(discountData))
      }
      this.$refs.discountSelectorModal.show()
    },
    closeEditor() {
      this.$refs.discountSelectorModal.hide()
    },

    cancel() {
      this.$emit('closed')
    },
    deleteData() {

    },
    saveData() {
      this.$refs.formClient.validate().then(success => {
        if (success) {
          this.$emit('discountSaved', this.discountData)
        }
      })
    },
    resetForm() {
      this.discountData = JSON.parse(JSON.stringify(this.blankDiscountData))
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
